import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/paintings',
    name: 'paintings',
    component: () => import('@/views/Paintings.vue'),
    beforeEnter: (to, from, next) => {
      store.commit('changeSelectedYear', '2024')
      next()
    },
  },
  {
    path: '/drawnings',
    name: 'drawnings',
    component: () => import('@/views/Drawnings.vue')
  },
  {
    path: '/portraits',
    name: 'portraits',
    component: () => import('@/views/Portraits.vue')
  },
  {
    path: '/graphics',
    name: 'graphics',
    component: () => import('@/views/Graphics.vue')
  },
  {
    path: '/sculptures',
    name: 'sculptures',
    component: () => import('@/views/Sculptures.vue')
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/Projects.vue')
  },
  {
    path: '/poetry',
    name: 'poetry',
    component: () => import('@/views/Poetry.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/:404',
    name: 'not-found',
    component: () => import('@/views/NotFound.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
